*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --bg: #121212;
  --bg-secondary: #212121;
  --box-shadow-bg-s: 5px 5px 10px #0d0d0d, -5px -5px 10px #353535;
  --color: #f2f2f2;
  --color-secondary: #d4d4d4;
  --color-third: #bb86fc;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: #121212;
    --bg-secondary: #212121;
    --box-shadow-bg-s: 5px 5px 10px #0d0d0d, -5px -5px 10px #353535;
    --color: #f2f2f2;
    --color-secondary: #d4d4d4;
    --color-third: #bb86fc;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --bg: #fff;
    --bg-secondary: #f2f2f2;
    --box-shadow-bg-s: 5px 5px 10px #d5d5d5, -5px -5px 10px #ffffff;
    --color: #1f1f1f;
    --color-secondary: #adadad;
    --color-third: #6200ee;
  }
}

body {
  background: var(--bg);
  color: var(--color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
